import * as React from "react";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
    ApiRequest,
    getQueryParameter,
    ProfileData,
    WHATA_ENDPOINTS,
    MUTATE_LOGIN,
} from "@plinknz/tah-website-elements";

import { Hero } from "../../../components/hero";
import * as ROUTES from "../../../config/router";
import { whataRequest } from "../../../service/member/whata";
import { UserContext } from "../../../service/reducers/user";
import { Loader } from "../../../components/loader";
import { EmailLoginForm } from "./email/email-login";
import { ResetPassword } from "./email/reset-password";
import { NewPasswordForm } from "./email/new-password";
import { SetupLogin } from "./email/setup-login";

interface LoginProps {
    whata?: ApiRequest;
}

export const Login = ({ whata = whataRequest }: LoginProps) => {
    const {
        state: { loading },
        dispatch,
    } = React.useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const [strapiLogin] = useMutation(MUTATE_LOGIN);

    const loginWithToken = async (token: string) => {
        dispatch({ type: "loading", isLoading: true });

        try {
            // eslint-disable-next-line no-param-reassign
            whata.token = token;
            const { data } = await whata.get<ProfileData>(WHATA_ENDPOINTS.me());

            if (data) {
                dispatch({
                    type: "update_user",
                    user: {
                        id: data.id.toString(),
                        email: data.email,
                        name: `${data.firstName} ${data.surname}`,
                    },
                });

                const variables = {
                    email: data.email,
                    token: whata.token,
                };

                const loginResponse = await strapiLogin({
                    variables,
                });

                dispatch({
                    type: "set_tokens",
                    payload: {
                        strapiToken: loginResponse.data.login.jwt as string,
                        whataToken: token,
                    },
                });
                dispatch({ type: "set_logged_in", isLoggedIn: true });
                history.push(ROUTES.members());
            }
        } catch (loginError: unknown) {
            console.warn(loginError);

            if (location.pathname !== ROUTES.membersLogin()) {
                dispatch({
                    type: "error",
                    error:
                        "Could not log you in. Please make sure you are registered with Ngāti Kuia or email us.",
                });
                history.push(ROUTES.membersLogin());
            }
        } finally {
            dispatch({ type: "loading", isLoading: false });
        }
    };

    function handleFacebookClick() {
        dispatch({ type: "loading", isLoading: true });
        const redirect = `${window.location.protocol}//${
            window.location.host
        }${ROUTES.membersLogin()}`;

        window.location.href = `${
            process.env.WHATA_URL
        }${WHATA_ENDPOINTS.pukamata()}?successRedirect=${redirect}&tenantId=KUIA`;
    }

    function checkAndHandleToken() {
        const token = getQueryParameter("token");

        if (token) {
            loginWithToken(token);
        } else {
            dispatch({ type: "reset" });
        }
    }

    function renderOptions() {
        return (
            <div className="button-list">
                <button
                    data-testid="login-facebook"
                    type="button"
                    className="button large facebook"
                    onClick={handleFacebookClick}>
                    <i className="button-fbook || fab fa-facebook-f" /> Login
                    with Facebook
                </button>
                <Link
                    to={ROUTES.membersLoginWithEmail()}
                    className="button large login"
                    data-testid="login-email">
                    <i className="button-icon left || fas fa-envelope" /> Login
                    with email
                </Link>
                <Link
                    to={ROUTES.membersLoginSetup()}
                    className="button large Primary"
                    data-testid="setup-login">
                    <i className="button-icon left || fas fa-key" /> Setup Login
                </Link>
            </div>
        );
    }

    React.useEffect(() => {
        checkAndHandleToken();
    }, []);

    return (
        <div className="main" data-testid="login">
            <Hero title="Login" />
            {(loading && (
                <div className="splash">
                    <Loader />
                </div>
            )) || (
                <div className="constrain-width small">
                    <div className="content">
                        <Switch>
                            <Route path={ROUTES.membersLoginWithEmail()}>
                                <EmailLoginForm
                                    loginWithToken={loginWithToken}
                                />
                            </Route>
                            <Route path={ROUTES.membersLoginReset()}>
                                <ResetPassword />
                            </Route>
                            <Route path={ROUTES.membersLoginSetup()}>
                                <SetupLogin />
                            </Route>
                            <Route path={ROUTES.membersLoginNewPassword()}>
                                <NewPasswordForm />
                            </Route>
                            <Route path="*">{renderOptions()}</Route>
                        </Switch>
                    </div>
                </div>
            )}
        </div>
    );
};
