import { useQuery } from "@apollo/client";
import {
    classNames,
    MemberNavigation as MemberNavigationQuery,
    GET_MEMA_NAVIGATION,
} from "@plinknz/tah-website-elements";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { generate } from "shortid";
import { Icon } from "../components/icon";
import * as ROUTES from "../config/router";
import { UserContext } from "../service/reducers/user";

const MemberNavigationLinks = ({
    activeItem,
    setActive,
}: {
    activeItem: string | null;
    setActive: (item: string | null) => void;
}) => {
    const { data } = useQuery<MemberNavigationQuery>(GET_MEMA_NAVIGATION);
    const links = data?.memberSection?.link_to_member_page.filter(
        (link) => link.page
    );

    return (
        <>
            {links?.map(({ page }) => {
                const hasChildPages =
                    page.show_children_pages &&
                    page.children &&
                    page.children.length > 0;
                const itemClass = classNames("subnav-item", {
                    "is-open": activeItem === page.slug,
                });

                return (
                    <div className={itemClass} key={generate()}>
                        <div className="subnav-item-inner">
                            <NavLink
                                className="subnav-item-link"
                                to={page.slug}>
                                {page.title}
                            </NavLink>
                            {hasChildPages && (
                                <button
                                    type="button"
                                    className="button || subnav-toggle"
                                    onClick={() =>
                                        setActive(
                                            page.slug === activeItem
                                                ? null
                                                : page.slug
                                        )
                                    }
                                    aria-label="submenu-button">
                                    <i className="icon || fas fa-angle-down" />
                                </button>
                            )}
                        </div>
                        {hasChildPages && (
                            <div className="subnav-children">
                                {page.children &&
                                    page.children.map((child) => (
                                        <NavLink
                                            key={page.slug + child.slug}
                                            to={child.slug}
                                            className="subnav-children-item">
                                            {child.title}
                                        </NavLink>
                                    ))}
                            </div>
                        )}
                    </div>
                );
            })}
            <div className="subnav-item" key={generate()}>
                <div className="subnav-item-inner">
                    <NavLink
                        className="subnav-item-link"
                        to={ROUTES.membersDetails()}>
                        Mōku | My Details
                    </NavLink>
                </div>
            </div>

            <div className="subnav-item cta" key={generate()}>
                <div className="subnav-item-inner">
                    <NavLink
                        to={ROUTES.membersLogout()}
                        className="subnav-calltoaction button">
                        Log out{" "}
                        <i className="button-icon fas fa-sign-out-alt" />
                    </NavLink>
                </div>
            </div>
        </>
    );
};

export const MemberNavigation = () => {
    const {
        state: { user, isLoggedIn },
    } = React.useContext(UserContext);
    const [activeItem, setActive] = React.useState<string | null>(null);

    return (
        <div className="subnav" data-testid="member-navigation">
            <div className="subnav-message">
                {isLoggedIn ? `Nau mai, ${user.name}` : ""}
            </div>

            <div className="subnav-inner">
                {(isLoggedIn && (
                    <MemberNavigationLinks
                        activeItem={activeItem}
                        setActive={setActive}
                    />
                )) || (
                    <NavLink
                        to={ROUTES.members()}
                        className="subnav-calltoaction button large tertiary"
                        data-testid="mema-login">
                        Ngā Mema
                        <Icon
                            className="button-icon || icon"
                            title="logo-mark-green"
                            name="logo-mark-green"
                        />
                    </NavLink>
                )}
            </div>
        </div>
    );
};
