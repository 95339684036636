import * as React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
    ApiRequest,
    getQueryParameter,
    WHATA_ENDPOINTS,
} from "@plinknz/tah-website-elements";
import * as ROUTES from "../../../../config/router";
import { Field } from "../../../../components/form/field";
import { UserContext } from "../../../../service/reducers/user";
import { whataRequest } from "../../../../service/member/whata";
import { parseError } from "../../../../utility/parse-error-html";

interface FormData {
    password: string;
    passwordConfirm: string;
    otc: string;
}

interface NewPasswordProps {
    whata?: ApiRequest;
}

export const NewPasswordForm = ({ whata = whataRequest }: NewPasswordProps) => {
    const {
        state: { error, passwordCreated },
        dispatch,
    } = React.useContext(UserContext);
    const {
        register,
        handleSubmit,
        errors,
        trigger,
        getValues,
        setValue,
    } = useForm<FormData>();

    async function handleFormSubmit(formData: FormData) {
        dispatch({ type: "loading", isLoading: true });
        dispatch({ type: "error", error: null });

        try {
            const { data, status } = await whata.put(
                WHATA_ENDPOINTS.password(),
                formData
            );
            const errorThreshold = 399;

            if (status > errorThreshold) {
                throw data;
            }

            dispatch({ type: "set_password_sent", passwordCreated: true });
        } catch (submitError: unknown) {
            let errorMessage: string | null =
                "There was an error when trying to set your password. Please try again";

            if (typeof submitError === "string") {
                errorMessage = parseError(submitError, errorMessage);
            }

            dispatch({
                type: "error",
                error: errorMessage,
            });
        } finally {
            dispatch({ type: "loading", isLoading: false });
        }
    }

    function checkAndHandleToken() {
        const token = getQueryParameter("reset_token");

        setValue("otc", token);
    }

    const isValid = () =>
        !errors.passwordConfirm &&
        !errors.password &&
        getValues().passwordConfirm &&
        getValues().password;

    React.useEffect(() => {
        checkAndHandleToken();
    }, []);

    return (
        <form
            className="form"
            onSubmit={handleSubmit(handleFormSubmit)}
            data-testid="new-password">
            {(passwordCreated && (
                <div className="form-alert">
                    <p>
                        We have updated your password. Please log in to
                        continue.
                    </p>
                    <Link
                        to={ROUTES.membersLoginWithEmail()}
                        className="button secondary">
                        Login
                    </Link>
                </div>
            )) || (
                <>
                    <Field
                        label="Password"
                        name="password"
                        error={{
                            hasError: !!errors.password,
                            message: "Please enter your password",
                        }}
                        ref={register({ required: true })}
                        placeholder="Password"
                        onChange={async (e) =>
                            trigger(e.currentTarget.name as "password")
                        }
                        type="password"
                        required
                    />
                    <Field
                        label="Confirm password"
                        name="passwordConfirm"
                        error={{
                            hasError: !!errors.passwordConfirm,
                            message:
                                "Please enter your password again to confirm",
                        }}
                        ref={register({ required: true })}
                        placeholder="Password confirmation"
                        onChange={async (e) =>
                            trigger(e.currentTarget.name as "passwordConfirm")
                        }
                        type="password"
                        required
                    />
                    <Field
                        label="otc"
                        type="hidden"
                        name="otc"
                        ref={register({ required: true })}
                    />
                    {error && <p className="field-error">{error}</p>}
                    <div className="form-navigation">
                        <button
                            className="button primary"
                            type="submit"
                            data-testid="button"
                            disabled={!isValid()}>
                            Set password
                        </button>
                        <Link
                            to={ROUTES.membersLogin()}
                            className="button tertiary login">
                            Login
                        </Link>
                    </div>
                </>
            )}
        </form>
    );
};
